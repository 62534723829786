.slick-prev:before, .slick-next:before  {
  content: '' !important;
}


.slick-slide {
  height: auto;
}

.slick-slide>div{
  height: 100%;
}
.slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  margin-left: 0;
}